import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   meta: {
  //     title: '青少年科技活动服务平台'
  //   },
  //   component: () => import('../views/index')
  // },
  //选择身份
  {
    path: '/',
    name: 'chooseStatus',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/chooseStatus/chooseStatus')
  },
  //查排名选择身份
  {
    path: '/pmchooseStatus',
    name: 'pmchooseStatus',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/chooseStatus/pmchooseStatus')
  },
   //奖品选择身份
   {
    path: '/jpchooseStatus',
    name: 'jpchooseStatus',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/chooseStatus/jpchooseStatus')
  },
  //奖状选择身份
   {
    path: '/jzchooseStatus',
    name: 'jzchooseStatus',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/chooseStatus/jzchooseStatus')
  },
  //添加身份信息
  {
    path: '/statusInfo',
    name: 'statusInfo',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/statusInfo/statusInfo')
  },
  //提交查排名身份信息
  {
    path: '/pmstatusInfo',
    name: 'pmstatusInfo',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/statusInfo/pmstatusInfo')
  },
   //领取奖品填写身份信息
   {
    path: '/jpstatusInfo',
    name: 'jpstatusInfo',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/statusInfo/jpstatusInfo')
  },
  //领取奖状填写身份信息
   {
    path: '/jzstatusInfo',
    name: 'jzstatusInfo',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/statusInfo/jzstatusInfo')
  },
  //领取奖状
   {
    path: '/diploma',
    name: 'diploma',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/reward/diploma')
  },
  {
    path: '/dati',
    name: 'dati',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/dati/index')
  },
  {
    path: '/exercise',
    name: 'exercise',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/dati/exercise')
  },
  {
    path: '/exercisecj',
    name: 'exercisecj',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/cj/exercisecj')
  },
  {
    path: '/info',
    name: 'info',
    meta: {
      title: '个人信息'
    },
    component: () => import('../views/info')
  },
  {
    path: '/paihang',
    name: 'paiHang',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/paihang')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/search')
  },
  {
    path: '/cj',
    name: 'cj',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/cj/index')
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      title: '普法知识竞赛'
    },
    component: () => import('../views/notice')
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
