//
//
//
//
//

export default {
  name: "app",
  data() {
    return {
      islogin: false,
      code: ''
    }
  },
  created() {
    // Toast.loading({ message: '正在加载资源，请稍等', forbidClick: true })
    // setTimeout(() => {
    //   this.islogin = true
    // }, 500)
    //this.getCode()
  },
  activated(){
	 
  },
  methods: {
    // 非静默授权，第一次有弹框
    getCode() {
      // let appId = 'wx51c9d55fa8def22b'
      let appId = 'wxf6cf313b0e14e06e'
      let local = window.location.href // 获取页面url
      this.code = this.getUrlCode().code // 截取code
      if (this.code == null || this.code === '') { // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        sessionStorage.setItem('code', this.code)
        //this.islogin = true
      }
    },
    // 截取url中的code方法
    getUrlCode() {
      let url = location.search
      this.winUrl = url
      let theRequest = new Object()
      if (url.indexOf("?") != -1) {
        let str = url.substr(1)
        let strs = str.split("&")
        for(let i = 0; i < strs.length; i ++) {
          theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
        }
      }
      return theRequest
    }
  }
}
