import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    answerData: [],
    points: '',
    rank: '',
    totaltime: '',
    minutes: '',
    seconds: ''
  },
  mutations: {
    setAnswer(state, payload) {
      state.answerData = payload.answerData
    },
    setPoints(state, payload) {
      state.points = payload.points
    },
    setRank(state, payload) {
      state.rank = payload.rank
    },
    setTotaltime(state, payload) {
      state.totaltime = payload.totaltime
    },
    setMinutes(state, payload) {
      state.minutes = payload.minutes
    },
    setSeconds(state, payload) {
      state.seconds = payload.seconds
    }
  },
  actions: {
    AsyncSetAnswer(content, payload) {
      content.commit('setAnswer', { answerData: payload.answerData })
    },
    AsyncSetPoints(content, payload) {
      content.commit('setPoints', { points: payload.points })
    },
    AsyncSetRank(content, payload) {
      content.commit('setRank', { rank: payload.rank })
    },
    AsyncSetTotaltime(content, payload) {
      content.commit('setTotaltime', { totaltime: payload.totaltime })
    },
    AsyncSetMinutes(content, payload) {
      content.commit('setMinutes', { minutes: payload.minutes })
    },
    AsyncSetSeconds(content, payload) {
      content.commit('setSeconds', { seconds: payload.seconds })
    }
  },
  modules: {
  }
})
